// npm
import React, { memo } from 'react'
import { SxProps, styled } from '@mui/material'
import { ButtonProps } from '@mui/material'

// atoms
import Button from '@atoms/buttons/Button'
import ExternalLink, { ExternalLinkProps } from '@atoms/links/ExternalLink'

interface LinkProps {
  fullWidth?: boolean
}

export interface ExternalButtonLinkProps
  extends LinkProps,
    Omit<ExternalLinkProps, 'fullWidth'> {
  buttonProps?: Omit<ButtonProps, 'children'>
  children: React.ReactNode
  sx?: SxProps
}

const StyledExternalLink = styled(ExternalLink, {
  shouldForwardProp: (prop) => prop !== 'fullWidth',
})<LinkProps>(({ fullWidth, theme }) => ({
  ...(fullWidth && {
    width: '100%',
  }),
}))

const ExternalButtonLink = ({
  buttonProps,
  fullWidth,
  children,
  ...props
}: ExternalButtonLinkProps) => {
  return (
    <StyledExternalLink fullWidth={fullWidth} {...props}>
      <Button fullWidth={fullWidth} {...buttonProps}>
        {children}
      </Button>
    </StyledExternalLink>
  )
}
export default memo(ExternalButtonLink)
